
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446!important;
        background: #ffffff;
    }
    .PlanStepOne{
        height: calc(100vh - 200px);
        padding: 20px 30px;
        /*display: flex;*/
        /*flex-direction: column;*/
        .PlanStepOneHeader{
            span:nth-child(1){
                color: #333333;
                font-size: 28px;
                font-weight: 500;
            }
            span:nth-child(2){
                color: #959697;
                font-size: 16px;
                font-weight: 500;
                margin-left: 20px;
            }
        }
        .plan-step-one-type{
            margin-top: 48px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            color: #333;
            .type-item {
                width: calc(50% - 96px);
                background: #F5F5F5;
                padding: 50px 40px 46px;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                &:nth-child(2n+1) {
                    /*margin-bottom: 40px;*/
                }
                &:hover {
                    color: #FD4446;
                    i {
                        font-size: 40px;
                        color: #FD4446;
                    }
                }
            }
            i {
                font-size: 40px;
                color: #DFDFDF;
            }
            .span1{
                font-size: 24px;
                font-weight: 500;
                padding-top: 30px;
            }
            .span2{
                font-size: 16px;
                font-weight: 400;
                padding-top: 30px;
            }

        }
        .closeBtn{
            width: 140px;
            margin-top: 50px;
            color: #333333;
            font-size: 20px;
        }
    }
